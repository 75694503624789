<template>
  <slide-over-right :id="`${sorId}-${id}`" editType="swap-battery-workflow">
    <div class="flex items-center justify-start mt-5 gap-2">
      <div
        class="flex items-center justify-center cursor-pointer back-button"
        @click="goBack"
      >
        <i class="fas fa-arrow-right"></i>
      </div>
      <div class="header-title">{{ titleText }}</div>
    </div>

    <div class="flex  w-full p-3 mt-5  bg-gray-50 rounded-md ">
      <div class="w-1/3 ">{{ vehicleProfile.name }}</div>
      <div class="w-1/3 flex justify-center">
        <BatteryStatus
          :level="getBatteryLevel"
          :height="`18px`"
          :width="`35px`"
          :text-class="`text-xs text-gray-600 ml-2`"
        />
      </div>
      <div class="w-1/3 flex justify-end ">
        {{ vehicleProfile.qr_code }}
      </div>
    </div>
    <div class="mt-5" v-if="workflowData">
      <div
        :key="`sb-${itemIndex}`"
        v-for="(item, itemIndex) in workflowData.items"
      >
        <div :class="`flex  w-full mt-0.5`" class=" list-container">
          <div class=" left-side flex flex-col items-center justify-center">
            <div class="flex items-center justify-center">
              <i
                v-if="item.status === 'IN_PROGRESS'"
                class="fa-solid fa-circle-half-stroke text-gray-700 text-xl"
              ></i>

              <i
                v-else-if="item.status === 'COMPLETED'"
                class="fa-regular fa-circle-check text-green-500 text-xl"
              ></i>
              <i
                v-else-if="item.status === 'FAILED'"
                class="fa-regular fa-circle-dot text-gray-700 text-xl"
              ></i>

              <i v-else class="fa-regular fa-circle text-gray-300 text-xl"></i>
            </div>
            <div
              class="w-0.5 h-full"
              :class="
                `${
                  itemIndex !== workflowData.items.length - 1
                    ? item.status === 'COMPLETED'
                      ? 'bg-green-500'
                      : 'bg-gray-300'
                    : 'bg-oWhite'
                } `
              "
            ></div>
          </div>
          <div class=" right-side flex  justify-between ">
            <div>
              <p
                class="font-medium  text-md "
                :class="
                  item.status === 'PENDING' ? 'text-gray-500' : 'text-oBlack'
                "
              >
                {{ item.title }}
              </p>
              <p class="text-xs" :class="getActionStatusClass(item)">
                {{ getActionStatusText(item) }}
                {{
                  item.status === 'IN_PROGRESS' &&
                  item.mode === 'automatic' &&
                  timeCounterInSec !== null
                    ? `(${timeCounterInSec}s)`
                    : ''
                }}
              </p>
            </div>
            <div class="flex items-center justify-center">
              <template v-if="item.status === 'FAILED'">
                <AppButton
                  height="30px"
                  :isLoading="isManualActionLoading"
                  @click="handleManualAction(item.code)"
                >
                  {{ failedCounter === 3 ? 'Skip' : 'Retry' }}
                </AppButton>
              </template>
              <template
                v-if="item.status === 'IN_PROGRESS' && item.mode === 'manual'"
              >
                <AppButton
                  height="30px"
                  :isLoading="isManualActionLoading"
                  @click="handleManualAction('confirm_prompt')"
                >
                  Continue
                </AppButton>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template
      v-slot:footer
      v-if="
        workflowData &&
          getLastCompletedItemData.completedIndex ===
            workflowData.items.length - 1
      "
    >
      <div class="flex flex-wrap justify-end ">
        <AppButton
          height="40px"
          :isLoading="isSubmitLoading"
          @click="handleSubmit"
        >
          COMPLETED
        </AppButton>
      </div>
    </template>
    <ConfirmFirst
      :id="`confirm_prompt${id}`"
      :key="`confirm_prompt${id}`"
      :isLoading="isLoading"
      :title="
        `${
          getCurrentInProgressItemData.progressData
            ? getCurrentInProgressItemData.progressData.title
            : ''
        } now`
      "
      :subtitle="
        `Please complete the task now.Slow internet might be effect to the task.`
      "
      :confirmButtonText="`Completed`"
      :cancelButtonVariant="`secondary`"
      :confirmButtonVariant="`primary`"
      @confirm="
        handleManualAction(
          getCurrentInProgressItemData.progressData
            ? getCurrentInProgressItemData.progressData.code
            : ''
        )
      "
      @cancel="handleManualAction('confirm_prompt_cancel')"
    />
  </slide-over-right>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import ConfirmFirst from '@/components/modals/ConfirmFirst'
import { VehicleSwapBatteryConfig } from '@/config/VehicleSwapBatteryConfig'
import { useEndpoints } from '@/composables'
import { xMan } from '@/utils'
import { SocketConfig } from '@/config/SocketConfig'
import ReconnectingWebsocket from 'reconnectingwebsocket'
export default {
  name: 'SwapBattery',
  components: {
    SlideOverRight,
    BatteryStatus,
    ConfirmFirst,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    vehicleProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sorId: VehicleSwapBatteryConfig.events.sorId,
      isLoading: false,
      workflowData: null,
      isManualActionLoading: false,
      isSubmitLoading: false,
      workflowItemTimer: null,
      timeCounterInSec: null,
      timeCounterIntervalId: null,
      failedCounter: 0,
    }
  },
  mounted() {
    EventBus.$on(VehicleSwapBatteryConfig.events.editingData, (data) => {
      if (this.id === `sb-${data.swapBatteryIndex}`) {
        const injectedWorkflowItems = data.workflowData.items.map((item) => {
          return {
            ...item,
            isCurrentTrack: false,
          }
        })
        const firstPendingIndex = this.getFirstIndex(
          injectedWorkflowItems,
          'PENDING'
        )
        const modifiedWorkflowItems = injectedWorkflowItems.map(
          (item, itemIndex) => {
            if (itemIndex === firstPendingIndex) {
              return {
                ...item,
                status: 'IN_PROGRESS',
              }
            } else {
              return {
                ...item,
              }
            }
          }
        )
        this.workflowData = {
          ...data.workflowData,
          items: modifiedWorkflowItems,
        }
        this.startListener()
      }
    })
  },
  beforeDestroy() {
    this.removeListener()
  },
  computed: {
    titleText() {
      return 'Workflow Checklist'
    },
    isEditing: function() {
      return !!this.primaryKey
    },
    actionButtonText: function() {
      return 'Submit'
    },
    getBatteryLevel() {
      return this?.vehicleProfile?.lock?.power_level || 0
    },
    getCurrentInProgressItemData() {
      if (this.workflowData && this.workflowData.items.length > 0) {
        const firstInProgressIndex = this.getFirstIndex(
          this.workflowData.items,
          'IN_PROGRESS'
        )
        if (firstInProgressIndex > -1) {
          return {
            progressIndex: firstInProgressIndex,
            progressData: this.workflowData.items[firstInProgressIndex],
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
    getLastCompletedItemData() {
      if (this.workflowData && this.workflowData.items.length > 0) {
        const lastCompletedIndex = this.getLastIndex(
          this.workflowData.items,
          'COMPLETED'
        )
        if (lastCompletedIndex > -1) {
          return {
            completedIndex: lastCompletedIndex,
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
    getCurrentFailedItemData() {
      if (this.workflowData && this.workflowData.items.length > 0) {
        const firstFailedIndex = this.getFirstIndex(
          this.workflowData.items,
          'FAILED'
        )
        if (firstFailedIndex > -1) {
          return {
            failedIndex: firstFailedIndex,
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
  },
  watch: {
    getCurrentInProgressItemData: {
      immediate: false,
      deep: true,
      handler(data) {
        if (Object.keys(data).length !== 0) {
          if (data.progressData.mode === 'automatic') {
            this.getFlowItemExecuteStatus(data.progressData.code)
              .then((res) => {
                if (res.status === 'PENDING') {
                  //update time for appearance
                  this.timeCounterInSec = 20
                  this.timeCounterIntervalId = setInterval(() => {
                    if (this.timeCounterInSec > 0) {
                      this.timeCounterInSec -= 1
                    } else {
                      this.clearWorkflowItemTimeInterval()
                    }
                  }, 1000)

                  //api call for certain time
                  this.workflowItemTimer = setTimeout(() => {
                    this.getFlowItemExecuteStatus(data.progressData.code)
                      .then((res) => {
                        if (res.status === 'PENDING') {
                          this.workflowData.items[data.progressIndex].status =
                            'FAILED'
                        } else {
                          this.workflowData.items[data.progressIndex].status =
                            'COMPLETED'
                        }
                      })
                      .catch((err) => {
                        console.log('flowExecuteStatus-err', { err })

                        this.workflowData.items[data.progressIndex].status =
                          'FAILED'

                        this.$notify({
                          group: 'bottomLeft',
                          type: 'error',
                          title: 'Error occurred!',
                          text: err.response.data.message,
                        })
                      })
                      .finally(() => {
                        this.clearWorkflowItemTimeout()
                      })
                  }, this.timeCounterInSec * 1000)
                } else {
                  this.workflowData.items[data.progressIndex].status =
                    'COMPLETED'
                }
              })
              .catch((err) => {
                console.log('flowExecuteStatus-err', { err })

                this.workflowData.items[data.progressIndex].status = 'FAILED'

                this.$notify({
                  group: 'bottomLeft',
                  type: 'error',
                  title: 'Error occurred!',
                  text: err.response.data.message,
                })
              })
          }
        }
      },
    },
    getLastCompletedItemData: {
      immediate: false,
      deep: true,
      handler(data) {
        if (Object.keys(data).length !== 0) {
          if (
            data.completedIndex < this.workflowData.items.length - 1 &&
            this.workflowData.items[data.completedIndex + 1].status ===
              'PENDING'
          ) {
            this.clearWorkflowItemTimeout()

            this.workflowData.items[data.completedIndex + 1].status =
              'IN_PROGRESS'
          }

          if (data.completedIndex === this.workflowData.items.length - 1) {
            this.clearWorkflowItemTimeout()
            this.removeListener()
          }
        }
      },
    },
  },
  methods: {
    closeModal() {
      dispatchEvent(
        new Event(VehicleSwapBatteryConfig.events.sorClose(this.id))
      )
    },
    goBack() {
      this.closeModal()
    },
    clearWorkflowItemTimeout() {
      clearTimeout(this.workflowItemTimer)
      this.workflowItemTimer = null
    },
    clearWorkflowItemTimeInterval() {
      clearInterval(this.timeCounterIntervalId)
      this.timeCounterIntervalId = null
      this.timeCounterInSec = null
    },
    getActionStatusClass(data) {
      if (data.status === 'COMPLETED') {
        return 'text-green-500'
      }
      if (data.status === 'IN_PROGRESS') {
        return 'text-gray-700'
      }
      if (data.status === 'FAILED') {
        return 'text-red-500'
      } else {
        return 'text-gray-400'
      }
    },
    getActionStatusText(data) {
      if (data.status === 'COMPLETED') {
        return 'Completed'
      }
      if (data.status === 'IN_PROGRESS') {
        return 'Processing'
      }
      if (data.status === 'FAILED') {
        return 'Failed'
      } else {
        return 'Pending'
      }
    },
    getFirstIndex(data, status) {
      return data.findIndex((item) => item.status === status)
    },
    getLastIndex(data, status) {
      return data.findLastIndex((item) => item.status === status)
    },
    async handleManualAction(type) {
      //instant task
      if (type === 'confirm_prompt') {
        this.isManualActionLoading = false
        this.$modal.show(`confirm_prompt${this.id}`)
        return
      }
      if (type === 'confirm_prompt_cancel') {
        this.isManualActionLoading = false
        this.$modal.hide(`confirm_prompt${this.id}`)
        return
      }

      if (this.failedCounter === 3) {
        // if it failed 3 times, then it will assign as COMPLETE
        this.failedCounter = 0
        this.workflowData.items[
          this.getCurrentFailedItemData.failedIndex
        ].status = 'COMPLETED'
        return
      }

      if (this.getCurrentFailedItemData.failedIndex) {
        this.workflowData.items[
          this.getCurrentFailedItemData.failedIndex
        ].status = 'IN_PROGRESS'
      }

      //asynchronous task
      this.isManualActionLoading = true
      this.$modal.hide(`confirm_prompt${this.id}`)

      await this.getFlowItemExecuteStatus(type)
        .then((res) => {
          this.failedCounter = 0

          if (res.status === 'PENDING') {
            this.workflowItemTimer = setTimeout(() => {
              this.getFlowItemExecuteStatus(
                this.getCurrentInProgressItemData.progressData.code
              )
                .then((res) => {
                  if (res.status === 'PENDING') {
                    this.workflowData.items[
                      this.getCurrentInProgressItemData.progressIndex
                    ].status = 'FAILED'
                  } else {
                    this.workflowData.items[
                      this.getCurrentInProgressItemData.progressIndex
                    ].status = 'COMPLETED'
                  }
                })
                .catch((err) => {
                  console.log('flowExecuteStatus-err', err)

                  this.workflowData.items[
                    this.getCurrentInProgressItemData.progressIndex
                  ].status = 'FAILED'

                  this.$notify({
                    group: 'bottomLeft',
                    type: 'error',
                    title: 'Error occurred!',
                    text: err.response.data.message,
                  })
                })
                .finally(() => {
                  this.clearWorkflowItemTimeout()
                })
            }, 10000)
          } else {
            this.workflowData.items[
              this.getCurrentInProgressItemData.progressIndex
            ].status = 'COMPLETED'
          }
        })
        .catch((err) => {
          console.log('flowExecuteStatus-err', { err })
          this.failedCounter++

          if (this.getCurrentInProgressItemData.progressIndex) {
            this.workflowData.items[
              this.getCurrentInProgressItemData.progressIndex
            ].status = 'FAILED'
          }

          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.message,
          })
        })
        .finally(() => {
          this.isManualActionLoading = false
        })
    },
    async getFlowItemExecuteStatus(flowItemCode) {
      const url = useEndpoints.vehicle.swapBatteryWorkflow.execute(
        this.workflowData.id
      )
      const data = new xMan({ code: flowItemCode }).toFormData()

      try {
        const res = await this.$http.patch(url, data)
        return Promise.resolve(res.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async handleSubmit() {
      this.isSubmitLoading = true
      await this.$http
        .patch(
          useEndpoints.vehicle.swapBatteryWorkflow.complete(
            this.workflowData.id
          )
        )
        .then(() => {
          this.$store.dispatch('fsTable/fetchData')
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: `Success`,
              text: `Successfully Completed`,
            },
            2000
          )
        })
        .catch((err) => {
          console.log('handleSubmit-err', { err })
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.message,
          })
        })
        .finally(() => {
          this.closeModal()
          this.isSubmitLoading = false
        })
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrlV1 +
        SocketConfig.channels.userWorkflow +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      // console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (
        payload[this.getCurrentInProgressItemData.progressData.code].status ===
        'COMPLETED'
      ) {
        this.workflowData.items[
          this.getCurrentInProgressItemData.progressIndex
        ].status = 'COMPLETED'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.list-container {
  width: 100%;
  min-height: 3.5rem;
}
.left-side {
  width: 2rem;
}
.right-side {
  width: calc(100% - 2rem);
}
</style>
