<template>
  <t-dropdown>
    <div
      slot="trigger"
      class="md:mr-2 relative"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
      }"
    >
      <button
        class="flex w-48 items-center justify-center h-35px px-3  mx-2 text-sm text-white transition duration-150 ease-in-out bg-blue-600 hover:bg-blue-700 border rounded-full shadow-md dropdown-button-size focus:outline-none focus:shadow-solid"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <loading
          :height="25"
          :opacity="0"
          color="#fff"
          v-if="smsReq"
          :active="true"
          loader="bars"
          :is-full-page="false"
        />
        <div v-if="!smsReq" class="flex items-center justify-center font-bold">
          <i class="fas fa-comment mr-2"></i>
          <span>SMS Command </span>

          <i class="fas fa-chevron-down ml-2"></i>
        </div>
      </button>
    </div>

    <div
      class="  bg-white py-4 rounded-md shadow-xs max-h-48 overflow-auto"
      slot-scope="{ hide }"
    >
      <div v-for="sms in smsData" :key="sms.id">
        <button
          class="bg-transparent outline-none font-medium text-14px focus:outline-none text-left px-3 py-2 w-full hover:bg-gray-200"
          @click="
            hide()
            handleVehicleSMSActions(sms.key, sms.value)
          "
        >
          {{ sms.name }}
          <template v-if="!['trigger_alarm', 'restart'].includes(sms.key)">
            <span
              class="bg-green-500 hover:bg-green-600 text-white rounded-md px-2 py-1 ml-1"
              v-if="sms.value === true"
              >ON</span
            >
            <span
              class="bg-red-500 hover:bg-red-600 text-white rounded-md px-2 py-1 ml-1"
              v-if="sms.value === false"
              >OFF</span
            >
            <span
              class="bg-green-400 hover:bg-green-600 text-white rounded-md px-2 py-1 ml-1"
              v-if="sms.value === 'L'"
              >LOW</span
            >
            <span
              class="bg-yellow-400 hover:bg-yellow-600 text-white rounded-md px-2 py-1 ml-1"
              v-if="sms.value === 'M'"
              >MEDIUM</span
            >
            <span
              class="bg-red-400 hover:bg-red-600 text-white rounded-md px-2 py-1 ml-1"
              v-if="sms.value === 'H'"
              >HIGH</span
            >
          </template>
        </button>
      </div>
    </div>
  </t-dropdown>
</template>
<script>
import { useEndpoints } from '@/composables'

export default {
  name: 'VehicleMoreSmsActions',
  components: {},
  props: {
    vehicleId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      smsReq: false,
      smsData: [
        {
          id: 'sms-0',
          name: 'Headlight',
          key: 'headlight',
          value: true,
        },
        {
          id: 'sms-1',
          name: 'Headlight',
          key: 'headlight',
          value: false,
        },
        {
          id: 'sms-2',
          name: 'Taillight',
          key: 'taillight',
          value: true,
        },
        {
          id: 'sms-3',
          name: 'Taillight',
          key: 'taillight',
          value: false,
        },
        {
          id: 'sms-4',
          name: 'Throttle',
          key: 'throttle',
          value: true,
        },
        {
          id: 'sms-5',
          name: 'Throttle',
          key: 'throttle',
          value: false,
        },
        {
          id: 'sms-6',
          name: 'Lock Speed Mode',
          key: 'lock_speed_mode',
          value: true,
        },
        {
          id: 'sms-7',
          name: 'Lock Speed Mode',
          key: 'lock_speed_mode',
          value: false,
        },
        {
          id: 'sms-8',
          name: 'Trigger Alarm',
          key: 'trigger_alarm',
          value: true,
        },
        {
          id: 'sms-9',
          name: 'Restart',
          key: 'restart',
          value: true,
        },
        {
          id: 'sms-10',
          name: 'Speed Mode',
          key: 'speed_mode',
          value: 'L',
        },
        {
          id: 'sms-11',
          name: 'Speed Mode',
          key: 'speed_mode',
          value: 'M',
        },
        {
          id: 'sms-12',
          name: 'Speed Mode',
          key: 'speed_mode',
          value: 'H',
        },
      ],
    }
  },
  methods: {
    async handleVehicleSMSActions(key, value) {
      this.smsReq = true
      let formData = new FormData()
      formData.append(key, value)
      await this.$http
        .post(
          useEndpoints.vehicle.sendControlFLagsBySMS(this.vehicleId),
          formData
        )
        .then((res) => {
          console.log('smsActions-res', res)
          this.smsReq = false
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Sent Successfully',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('smsActions-err', { err })
          this.smsReq = false
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.vehicle-action-button {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  font-size: 14px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  // colors
  &.locked {
    @apply bg-oGreen;
  }
  &.unlocked {
    @apply bg-oPurple;
  }
  &.inactive {
    background-color: #c7c7c7;
  }

  // variants
  &.badge {
    width: 110px;
    height: 20px;
    border-radius: 3px;
  }

  &.emphasize {
    width: 155px;
    @apply flex items-center justify-between shadow-md rounded-full h-35px;
    @apply px-3 py-0 pl-3 text-center cursor-pointer;

    &.inactive {
      @apply bg-gray-300;
    }
  }
}
</style>
