<template>
  <div class="flex items-center value">
    <div style="width: 100px">
      <TaskAddEditStatusDropdown
        v-model="taskStatusProxy"
        :variant="`badge`"
        :options="taskStatusOptions"
        :disabled="getTaskStatusDisabled"
        :hide-error="true"
        @change="onChangeTaskStatus"
      />
    </div>

    <AppButton
      variant="transparent"
      height="20px"
      text=""
      rounded="sm"
      :is-loading="taskStatusReq"
    />
  </div>
</template>

<script>
import { httpClient } from '@/services'
import TaskAddEditStatusDropdown from './AddEditStatusDropdown.vue'
import { useEndpoints } from '@/composables'
import { taskStatusOptions } from '.'

export default {
  name: 'TaskListStatusDropdown',

  components: {
    TaskAddEditStatusDropdown,
  },

  props: {
    primaryKey: {
      required: false,
    },
    taskStatus: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    taskStatusReq: false,
    taskStatusProxy: '',
    taskStatusOptions: taskStatusOptions,
  }),

  computed: {
    getTaskStatusDisabled() {
      return ['DROPPED', 'CANCELLED'].includes(this.taskStatusProxy)
    },
  },

  watch: {
    taskStatus: {
      deep: false,
      immediate: true,
      handler(newVal) {
        this.taskStatusProxy = newVal
      },
    },
  },
  methods: {
    async onChangeTaskStatus(newValue, oldValue) {
      if (this.$acl.canNotUpdate('tasks')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'tasks',
          })
        )
        return
      }
      this.taskStatusReq = true
      const url = useEndpoints.task.update(this.primaryKey)
      const data = new FormData()
      data.append('task_status', newValue)
      await httpClient
        .patch(url, data)
        .then((res) => {
          // console.log(res.data)
          this.$notify({
            group: 'bottomRight',
            type: 'success',
            title: `Task Status Changed`,
            text: `The task is now in '${res.data.task_status}' state`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.taskStatusProxy = oldValue
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.message ??
              'Could not change task status, please try again.',
          })
        })
        .finally(() => (this.taskStatusReq = false))
    },
  },
}
</script>
